<template>
  <Planner></Planner>
</template>

<script>
import Planner from "@/components/planner/planner.vue";
export default {
  components: {
    Planner
  },
  data() {
    return {
      test: [
        {
          id: 1,
          title: 'New',
          borderClass: 'border-top-orange',
          list: [
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 1, borderClass: "border-left-orange", client: "Introspect Technology", total: "354,000.00" },
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 2, borderClass: 'border-left-orange', client: "Introspect Technology", total: "354,000.00" },
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 3, borderClass: 'border-left-orange', client: "Introspect Technology", total: "354,000.00" },
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 4, borderClass: 'border-left-orange', client: "Introspect Technology", total: "354,000.00" }
          ]
        },
        {
          id: 2,
          title: 'Sent to Client',
          borderClass: 'border-top-blue',
          list: [
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 5, borderClass: 'border-left-blue', client: "Introspect Technology", total: "354,000.00" },
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 6, borderClass: 'border-left-blue', client: "Introspect Technology", total: "354,000.00" }
          ]
        },
        {
          id: 3,
          title: 'Approved by Client',
          borderClass: 'border-top-green',
          list: [
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 7, borderClass: 'border-left-green', client: "Introspect Technology", total: "354,000.00" },
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 8, borderClass: 'border-left-green', client: "Introspect Technology", total: "354,000.00" },
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 9, borderClass: 'border-left-green', client: "Introspect Technology", total: "354,000.00" }
          ]
        },
        {
          id: 4,
          title: 'Approved by Manager',
          borderClass: 'border-top-purple',
          list: [
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 10, borderClass: 'border-left-purple', client: "Introspect Technology", total: "354,000.00" },
            { cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 11, borderClass: 'border-left-purple', client: "Introspect Technology", total: "354,000.00" }
          ]
        }
      ],
      dragBlock: false,
      dragItem: false
    }
  },
}
</script>
